// IMPORTS
@import (once) "/node_modules/swiper/swiper.less";
@import (once) "/node_modules/swiper/modules/navigation.less";
@import (once) "/node_modules/swiper/modules/pagination.less";
@import (once) "/node_modules/swiper/modules/autoplay.less";

@import (once) "settings.less";
 
// GENERAL
body {
    overflow-x: hidden;
    background-color: white;
}

body[data-type="cart"] {
    .sidebar {
        display: none !important;
    }

    #content {
        grid-column: span 2;
    }
}

main {
    .grid();
    padding: 0 0 var(--rowGap);
    grid-gap: var(--rowGap) 32px;

    &:not(.aside) {
        grid-template-columns: minmax(1px, 1fr);

        #tree {
            display: none;
        }
    }

    &.aside {
        grid-template-columns: 280px minmax(1px, 1fr);
        grid-template-rows: auto 1fr;

        #newsletter,
        .last-visited-wrapper {
            grid-column: span 2;
        }
    }
}

body[data-type="mainpage"] #content {
    display: flex;
    flex-direction: column;
    gap: var(--rowGap);
    margin-top: 32px;
}

@media (min-width: 1280px) {
    #breadcrumb,
    #content {
        grid-column: 2 / 3;
    }
}

[data-type="mainpage"] #breadcrumb {
    display: none;
}

body:not([data-type="mainpage"]) {

    .sidebar {
        grid-row: span 2;
    }
}

.goshop-modal-content {
    display: none;
}

#container {
    min-height: 100vh;
    min-height: -webkit-fill-available;

    // IMPORTANT
    display: flex;
    flex-direction: column;

    > *:not(.inner) {
        width: 100%;
    }

    #footer {
        margin-top: auto;
    }
}

.header-sentinel {
    position: absolute;
    top: 110px;
    left: 0;
}

.privacy-sentinel {
    height: 0;
    clear: both;
}

// TOP BAR
.top-bar {
    position: relative;
    line-height: 22px;
    padding: 12px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 32px;
    position: relative;

    &:after {
        .hr-after();
        bottom: 0;
    }

    &:has(.header-shipment) {
        justify-content: flex-end;
    }

    > * {
        display: flex;
        align-items: center;
    }
}

body[data-type="cart"] {
    .top-bar {
        display: none !important;
    }
}

.header-shipment {
    margin-right: auto;
    display: flex;
    align-items: center;

    &:only-child {
        justify-self: center;
        margin-right: initial;
    }

    &:before {
        .small-icon();
        content: "\e91b";
        margin-right: 12px;
    }

    > b {
        margin-left: 4px;
    }
}

.header-contact {
    gap: 24px;

    > a {
        display: flex;
        align-items: center;
        padding-left: 36px;
        position: relative;
        color: @font-color;

        &:before {
            .small-icon();
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
        }

        &.phone:before {
            content: "\e91a";
        }

        &.email:before {
            content: "\e919";
        }

        @media (min-width: 1280px) {
            &:hover {
                text-decoration: underline;
            }
        }
    }
}

.header-social-links {
    gap: 6px;

    > a {
        color: @font-color;

        &:before {
            .small-icon();
        }

        &.facebook-link:before {
            content: "\e918";
        }

        &.instagram-link:before {
            content: "\e917";
        }

        @media (min-width: 1280px) {
            &:hover {
                color: @sec-color;
            }
        }
    }
}

// SHOP HEADER
@media (min-width: 1280px) {
    .shop-header {
        position: sticky;
        top: 0;
        left: 0;
        background-color: white;
        width: 100%;
        transition: 0 box-shadow 0 ease-in-out;
        z-index: 1000;

        @media (max-width: 1680px) {
            .inner > * {
                max-width: 1170px;
            }
        }

        &.stuck {
            .shadow();
            transition: box-shadow .1s ease-in-out;

            .shop-header-container {
                padding: 12px 0;
            }

            .mainmenu,
            .below-header-bar {
                position: absolute;
                left: 50%;
                transform: translate(-50%, 100%);
                width: 1440px;
                opacity: 0;
                visibility: hidden;
            }

            .mainmenu {
                bottom: 0;
            }

            .below-header-bar {
                bottom: -64px;
            }
        }
    }

    main {
        transition: margin 0.2s ease-in-out;
    }

    body:has(.shop-header.stuck) main {
        padding-top: 64px;
        margin-top: 40px;
    }

    body:has(.shop-header.stuck .below-header-bar) main {
        padding-top: 120px;
    }

    body[data-type="cart"] main {
        padding-top: 0 !important;
        margin-top: 0 !important;
    }
}

.mainmenu,
.below-header-bar {
    opacity: 1;
    transition:
        opacity 0.1s ease-in-out,
        visibility 0.1s ease-in-out;
}

.shop-header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 32px 0;
    position: relative;
    z-index: 2;
    transition:
        padding 0.2s ease-in-out;

    &:before {
        .full-bg();
        background-color: white;
    }
}

// LOGO
.logo {
    height: 56px;
    aspect-ratio: ~"240/56";
    transform-origin: left top;

    img {
        .img-contain();
    }
}

// HEADER ICONS
.header-tools-wrapper {
    display: flex;
    gap: 31px;
}

@media (min-width: 1280px) {
    .tree-trigger {
        display: none;
    }

    .tree-canvas {
        display: none;
    }

    .log-button,
    #wishlist-box,
    #cart-box {
        position: relative;
        padding-left: 45px;

        &:before {
            font-family: icomoon;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            font-size: 32px;
            line-height: 32px;
            height: 32px;
            color: @header-color;
        }

        span,
        b {
            display: block;
            color: @font-color;
            text-transform: uppercase;
            font-family: @sec-font-family;
            line-height: 1.2;
            letter-spacing: 0.05em;
        }

        em {
            display: none;
        }

        b {
            margin-top: -3px;
        }

        &:hover {

            span,
            b {
                color: @sec-color;
            }
        }
    }

    .log-button {
        padding-left: 40px;

        &:before {
            content: "\e915";
        }
    }

    #wishlist-box {

        &:before {
            content: "\e914";
        }

        &[data-wishlist-count="0"] {
            padding-left: 40px;

            &:after {
                display: none;
            }
        }
    }

    #cart-box {
        padding-left: 40px;

        &:has(i) {
            padding-left: 45px;
        }

        &:before {
            content: "\e913";
        }
    }
}

#wishlist-box:after,
#cart-box i {
    content: attr(data-wishlist-count);
    font-style: normal;
    position: absolute;
    top: -2px;
    left: 23px;
    border-radius: 50%;
    background-color: @sec-color;
    color: @header-color;
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 12px;
    line-height: 1;
}

// SEARCH ENGINE
.search-engine-trigger,
.search-engine-close {
    display: none;
}

@media (min-width: 1280px) {
    #search-engine {
        position: relative;
        width: 468px;
        z-index: 100;

        &.show,
        &.show-last-phrases {

            #suggestions {
                opacity: 1;
                visibility: visible;
                transform: translateY(0);
                transition: all .2s ease-in-out;
            }
        }

        &:not(.show-last-phrases) {

            .suggestion-last-phrases {
                display: none;
            }

            &:has(.suggestion-result-products:empty) {

                .no-products-info {
                    display: block !important;
                }
            }
        }

        input[type="text"] {
            width: 100%;
            height: 48px;
            padding: 0 64px 0 24px;
            background-color: @light-bg;
            color: @header-color;
            font-size: 16px;
            font-family: @main-font-family;
            border: none;

            &::-webkit-input-placeholder {
                color: @font-color;
            }
            &::-moz-placeholder {
                color: @font-color;
            }
            &:-ms-input-placeholder {
                color: @font-color;
            }
            &:-moz-placeholder {
                color: @font-color;
            }
        }

        button {
            position: absolute;
            top: 0;
            right: 0;
            width: 64px;
            height: 48px;
            color: @header-color;
            font-size: 32px;
            display: flex;
            justify-content: center;
            align-items: center;

            &:after {
                content: "\e916";
                font-family: icomoon;
            }

            &:hover {
                color: @sec-color;
            }
        }
    }
}

#suggestions {
    position: absolute;
    opacity: 0;
    visibility: hidden;
}

// MAIN MENU
.mainmenu {
    position: relative;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 16px;
    height: 64px;

    &:after {
        .full-bg();
        background-color: @main-color;
    }

    li {
        height: 100%;
        display: flex;
        align-items: center;
    }

    a {
        color: white;
        text-transform: uppercase;
        font-family: @sec-font-family;
        line-height: 1.2;
        letter-spacing: 0.05em;
        height: 100%;
        font-size: 20px;
        display: flex;
        align-items: center;

        @media(min-width: 1280px) {
            &:hover {
                color: @sec-color;
            }
        }
    }
}

// BELOW HEADER BAR
.below-header-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px 28px;
    position: relative;
    color: @header-color;

    &:after {
        .full-bg();
        background-color: @light-bg;
    }

    > span {
        display: flex;
        justify-content: center;
        align-items: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-inline: 10px 34px;
        font-weight: bold;
    }

    .bar-close {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        color: @header-color;

        &:after {
            .small-icon();
            content: "\e912";
        }

        @media (min-width: 1280px) {
            &:hover {
                color: @sec-color;
                cursor: pointer;
            }
        }
    }
}

// DEFAULT-BANNER
.default-banner {
    width: 100%;
    position: relative;

    img {
        .img-crop();
    }

    > b {
        position: absolute;
        bottom: 16px;
        left: 0;
        right: 0;
        text-align: center;
        margin: 0 auto;
        width: fit-content;
        padding: 10px 12px;
        background: rgba(49,58,73, 0.8);
        text-align: center;
        font-family: @sec-font-family;
        text-transform: uppercase;
        font-weight: 500;
        font-size: 24px;
        line-height: 1.2;
        color: white;
        transition: background-color 0.1s ease-in-out;
        max-width: 80%;
    }

    @media (min-width: 1280px) {
        &:hover {
            > b {
                background-color: #313A49;
            }
        }
    }
}

// SIDEBAR
.sidebar {
    display: flex;
    flex-direction: column;
    gap: 32px;
    margin-top: 32px;
}

// TREE
@media (min-width: 1280px) {
    .cms-rwd,
    .tree-header {
        display: none;
    }

    #tree {
        display: flex;
        flex-direction: column;

        &:before {
            content: "Kategorie";
            padding: 16px 24px;
            font-size: 20px;
            font-weight: 500;
            line-height: 1.2;
            letter-spacing: 0.05em;
            font-family: @sec-font-family;
            text-transform: uppercase;
            color: white;
            background-color: @main-color;
        }

        > nav {
            border: 1px solid @border-color;
        }

        li {
            border-bottom: 1px solid @border-color;
            position: relative;

            &:last-child {
                border-bottom: 0;
            }

            &.downarrow {

                > a {
                    border-bottom: 1px solid @border-color;
                }

                > button:after {
                    transform: scaleY(-1);
                }

                > ul {
                    display: block;
                }
            }

            ul {
                display: none;
            }
        }

            li.t0 {

                > a {
                    display: block;
                    color: @font-color;
                    line-height: 1.2;
                    padding: 12px 0;
                    padding-left: 36px;
                    padding-right: 50px;
                }
            }

        a {
            position: relative;

            &:hover {
                text-decoration: underline;
            }
        }

        li:not(.t0) {

            a {
                color: @header-color;
                padding: 8px 0;
                padding-right: 50px;
                display: block;
            }
        }

        li.t1 > a {
            padding-left: 44px;
        }

        li.t2 > a {
            padding-left: 52px;
        }

        li.t3 > a {
            padding-left: 60px;
        }

        li.selected > a {
            color: @main-color;
        }

        button {
            position: absolute;
            top: 0;
            left: 0;
            width: 36px;
            height: 46px;
            font-size: 9px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: @font-color;

            &:after {
                content: '\e800';
                font-family: icomoon;
            }
        }

        small {
            font-size: 14px;
            line-height: 1.2;
            color: @font-color;
            position: absolute;
            top: 0;
            right: 0;
            font-weight: normal;
            height: 100%;
            width: 40px;
            display: flex;
            align-items: center;
        }
    }
}

// PRODUCTS
@keyframes productImageOpacity {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.products-list {
    .grid();
    grid-template-columns: repeat(var(--prodCols), minmax(0, 1fr));
    gap: var(--prodGapVertical) var(--prodGap);
    width: 100%;

    &:empty {
        display: none;
    }
}

.product {
    position: relative;
    outline: 1px solid @border-color;
    padding: 24px 16px;
    .grid();
    grid-template-rows: auto 1fr;
    gap: 16px;
    overflow: hidden;

    &.swiper-slide {
        box-sizing: border-box;
        outline: none;
        border: 1px solid @border-color;

        .product-name {
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            display: -webkit-box !important;
            height: ~"calc(2 * (var(--fontSize) * var(--lineHeight)))";
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}

.product-image {
    aspect-ratio: 1 / 1;
    position: relative;
    transition: transform .1s ease-in-out;
}

.promo-badge {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: 700;
    font-size: 14px;
    line-height: 1.2;
    background-color: @error-color;
    padding: 3px 11px;
}

a.product-name {
    font-weight: 700;
    line-height: 1.2;
    display: block;
    color: @header-color;
    transition: opacity .1s ease-in-out;
    z-index: 1; // IMPORTANT
    text-align: center;

    &:after {
        content: '';
        position: absolute;
        inset: 0;
    }

    &:first-letter {
        text-transform: uppercase;
    }
}

.product-bottom-wrapper {
    display: flex;
    justify-content: space-between;
    position: relative;
    gap: 6px;
    z-index: 1;
}

.product-price {
    height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    b, del {
        line-height: 1.2;

        &:after {
            content: ' zł';
        }
    }

    b {
        font-weight: 700;
        color: @header-color;
    }

    del {
        font-size: 14px;
        color: #888888;
        margin-top: 2px;
    }
}

.product-add,
.product-wishlist {
    width: 40px;
    height: 40px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:after {
        font-family: icomoon;
        font-size: 22px;
    }
}

.product-add {
    background-color: @main-color;
    color: white;

    &:after {
        content: "\e913";
    }

    &.disabled {
        display: none;
    }

}

.product-wishlist {
    margin-left: auto;
    background-color: #E7E7E7;
    color: @header-color;

    &:after {
        content: '\e809';
    }

    &.existing {
        background-color: @sec-color;
        color: white !important;
    }
}

@media(min-width: 1280px) {

    .product-add:hover {
        background-color: @sec-color;
    }

    .product-wishlist {

        &:hover {
            color: @sec-color;
        }

        &.existing:hover {
            background-color: darken(@sec-color, 5%);
        }
    }
}

.product-hover {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    background-color: rgba(255,255,255, 0.8);
    padding: 40px 10px;
    transition: opacity .1s ease-in-out;
    text-align: center;

    > span,
    > p {
        transform: translateY(-8px);
        transition: transform .1s ease-in-out;
        --lineHeight: 1.2;
        line-height: 1.2;
    }

    > span {
        font-weight: 700;
        color: @header-color;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        display: block;
        display: -webkit-box !important;
        max-height: ~"calc(3 * (var(--fontSize) * var(--lineHeight)))";
        overflow: hidden;
        text-overflow: ellipsis;
    }

    > p {
        margin-top: 16px;
        color: @font-color;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        display: block;
        display: -webkit-box !important;
        max-height: ~"calc(5 * (var(--fontSize) * var(--lineHeight)))";
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

@media (min-width: 1280px) {
    .product:hover {

        .product-name {
            opacity: 0;
            transition-delay: .2s;
        }

        .product-hover {
            opacity: 1;
            transition-delay: .2s;

            > strong,
            > p {
                transition-delay: .2s;
                transform: translateY(0);
            }
        }
    }
}

// BREADCRUMB
#breadcrumb {
    padding-block: 48px 16px;
    margin-bottom: ~"calc(var(--rowGap) * -1)";

    ul {
        display: flex;
        flex-wrap: wrap;
    }

    li {
        display: flex;

        &:after {
            content: '-';
            margin: 0 4px;
        }

        &:last-child {

            &:after {
                display: none;
            }
        }
    }

    a,
    span {
        color: @font-color;
        display: block;
    }
}

[data-type="mainpage"] #breadcrumb {
    display: none;
}

// AMOUNT INPUT
.amount-input-wrapper {

    input[type="text"] {
        display: block;
        border: 1px solid @border-color;
        width: 100%;
        color: @header-color;
        text-align: center;
        border-radius: var(--borderRadius);
    }

    button {
        position: absolute;
        top: 0;
        width: 33%;
        height: 100%;
        cursor: pointer;
        touch-action: manipulation;
        z-index: 1;
        background-color: transparent;
        border-radius: var(--borderRadius);

        &:before, // -
        &:after { // |
            position: absolute;
            display: block;
            border-radius: 50%;
            background: @header-color;
            content: '';
            left: 50%;
            top: calc(50% + 1px);
            transform: translate(-50%, -50%);
        }

        &.minus {
            left: 0;

            &:after {
                display: none;
            }
        }

        &.plus {
            right: 0;
        }

        &:hover {

            &:before,
            &:after {
                background-color: @sec-color !important;
            }
        }
    }
}